import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Layout from 'components/Layout'
import Link from 'components/Link'
import { useTheme } from 'components/Theming'
import Container from 'components/Container'
import { rhythm } from '../lib/typography'
import Helmet from 'react-helmet'
import axios from 'axios'
import moment from 'moment'
import queryString from 'query-string'
import isEmpty from 'lodash/isEmpty'

class FrontUserAdmin extends React.Component {
  state = {}
  componentDidMount() {
    // axios
    //   .get(
    //     `${process.env.NETLIFY_FUNCTIONS_URL}/userData?secret=${process.env.FRONT_APP_SECRET}&email=joel@egghead.io`,
    //   )
    //   .then(({ data }) => {
    //     this.setState({ user: data })
    //   })
    const { auth_secret } = queryString.parse(location.search)
    Front.on('conversation', ({ contact }) => {
      const { auth_secret } = queryString.parse(location.search)
      axios
        .get(
          `${process.env.NETLIFY_FUNCTIONS_URL}/userData?secret=${auth_secret}&email=${contact.handle}`,
        )
        .then(({ data }) => {
          this.setState({ user: data })
        })
    })
  }
  render() {
    const { user } = this.state
    let title = 'loading...'

    if (user && user.is_banned) {
      title = 'BANNED'
    } else if (user && user.instructor) {
      title = 'INSTRUCTOR'
    } else if (user && user.is_pro) {
      title = 'MEMBER'
    } else if (user) {
      title = 'NON-MEMBER'
    }
    return (
      <div>
        <Helmet>
          <script
            type="text/javascript"
            src="https://dl.frontapp.com/libs/frontjs.min.js"
          />
        </Helmet>

        {!isEmpty(user) && (
          <>
            <img src={user.avatar_url} />
            <div>{title}</div>
            {user.http_url && (
              <div>
                <a target="_blank" href={user.http_url}>
                  egghead profile
                </a>
              </div>
            )}
            {user.stripe_http_url && (
              <div>
                <a target="_blank" href={user.stripe_http_url}>
                  Stripe customer account
                </a>
              </div>
            )}
            {!isEmpty(user.gifts) && (
              <>
                <h3>Gifts:</h3>
                {user.gifts.map(gift => (
                  <div key={gift.guid}>
                    {gift.claimed_at
                      ? `claimed on ${moment(gift.claimed_at).format(
                          'YYYY-MM-DD',
                        )}`
                      : 'unclaimed'}{' '}
                    (
                    <a target="_blank" href={gift.http_url}>
                      link
                    </a>
                    )
                  </div>
                ))}
              </>
            )}
            {!isEmpty(user.purchased) && (
              <>
                <h3>Purchases:</h3>
                {user.purchased.map(purchase => (
                  <div key={purchase.id}>
                    <strong>{purchase.title}</strong> on{' '}
                    {moment(purchase.created_at).format('YYYY-MM-DD')} (
                    <a target="_blank" href={purchase.admin_http_url}>
                      view
                    </a>
                    )
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </div>
    )
  }
}

export default function Index({ data: { site, allMdx } }) {
  const theme = useTheme()
  return (
    <Layout site={site}>
      <Container
        css={css`
          padding-bottom: 0;
        `}
      >
        <FrontUserAdmin />
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      ...site
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { ne: false } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 190)
          id
          fields {
            title
            slug
            date
          }
          parent {
            ... on File {
              sourceInstanceName
            }
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            banner {
              childImageSharp {
                sizes(maxWidth: 720) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            slug
            keywords
          }
        }
      }
    }
  }
`
